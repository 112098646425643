@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.App {
  text-align: center;
  background-color: #f1f2f2;
}



